import { colors } from '../colors/mainGradient';

export const info = {
  firstName: 'Evan',
  lastName: 'Crabtree',
  initials: '🦀🌳', 
  position: 'a Full Stack Game Developer',
  selfPortrait: 'self', // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: '🌉',
      text: 'based in San Francisco, CA',
    },
    {
      emoji: '💻',
      text: 'Full Stack Developer',
    },
    {
      emoji: '🕹️',
      text: 'Game Developer',
    },
    {
      emoji: '📧',
      text: 'evancrabtree41@gmail.com',
    },
  ],
  socials: [
    {
      link: 'https://evancrabtree.me/crabtree_evan_resume.pdf', // this should be https://yourname.com/resume.pdf once you've deployed
      icon: 'fa fa-file',
      label: 'resume',
    },
    {
      link: 'https://github.com/EvanCrabtree',
      icon: 'fa fa-github',
      label: 'github',
    },
    {
      link: 'https://www.linkedin.com/in/evan-m-crabtree/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
  ],
  bio: "Hey! I'm Evan Crabtree. I'm a Full Stack Game Developer. I am working as a Software Engineer at Niantic developing Pokemon Go. I enjoy creating games and building web applications with an emphasis in shared experiences that build community. I recently graduated with my Master's in Computer Science specializing in Arts Media Engineering.",
  experience: [
    {
      company: 'Niantic, Pokemon Go',
      description: 'Full stack development expanding the Pokestop Showcase feature'
    },
    {
      company: 'Niantic, Geospatial Data',
      description: 'Full stack web development of surveyor management and 3D mesh web visualization tools'
    },
    {
      company: 'Undergraduate Research Machine Learning and Databases',
      description: 'System design and development of an automatic query rewriter using a contrastive learning approach'
    }
  ],
  skills: {
    // you dont have to strictly follow the 'languages', 'frameworks', 'databases', 'cloudServices', and 'tools' categories. You can add your own if you'd like.
    // but the structure should be the same, an array of strings and the key should be one word, no spaces.
    languages: ['Java', 'TypeScript', 'HTML', 'CSS', 'C\#', 'PostgreSQL', 'Python', "C++"],
    technologies: [
      'React', 'Django', 'Unity', 'Node.js', 'Express', 'Google Cloud', 'Jira', 'Redis'
    ],
    tools: [
      'Git',
      'Postman',
      'Docker',
      'Node Package Manager',
      'Gradle',
      'Jira',
    ],
  },
  hobbies: [
    {
      label: 'Cooking',
      emoji: '🍳',
    },
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Baking',
      emoji: '🍪',
    },
    {
      label: 'Coffee',
      emoji: '☕︎',
    },
    {
      label: 'Board Games',
      emoji: '♟️',
    },
    {
      label: 'Traveling',
      emoji: '✈️',
    }
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    //   for the null ones, the button will not show up
    {
      title: 'GAS Games',
      subtitle: 'A wishlist and collection platform for over 65,000 board and video games',
      live: null, //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/GasDevils/GamesAndStuff', // this should be a link to the **repository** of the project, where the code is hosted.
      image: 'https://i.ibb.co/42GWBvF/logo.png'
    },
    {
      title: 'Kitty Kart',
      subtitle: 'Multiplayer kart racer using AI generated tracks and racers',
      live: 'https://night0.itch.io/kitty-kart',
      source: 'https://github.com/District-AI1/crispy-octo-bassoon',
      image: 'https://img.itch.zone/aW1nLzEyMjUwMzk3LnBuZw==/original/hPxt%2B6.png',
    },
    {
      title: 'Cool Routes',
      subtitle: "Real time mean radiant temperature based routing web app",
      live: 'https://coolroutes.sensableheatscapes.com/',
      source: 'https://github.com/SHaDE-Lab/Shade_Webapp/',
      image: 'https://i.ibb.co/2kn2Q3G/Screenshot-2024-07-07-at-2-49-50-PM.png',
    },
  ],
};
