import React from 'react';
import Style from './Terminal.module.scss';
import classNames from 'classnames';
import { Box } from '@mui/material';

const iconClass = 'fa fa-circle';


function Terminal(props) {
  const { header, text } = props;

  return (
    <Box
      component={'section'}
      className={classNames(Style.terminal, Style.shadowed)}
      width={{ xs: '80%', md: '50%' }}
      borderRadius={'0.5rem'}
      mb={'4rem'}
    >
      <Box
        sx={{
          backgroundColor: '#8c8c8c',
          position: 'relative',           // Add this line for positioning
          p: '0.5rem',
          borderRadius: '0.5rem 0.5rem 0 0',
          fontSize: '1rem',
          display: 'flex',
          justifyContent: 'flex-start',   // Align items to the start (left-aligned)
          alignItems: 'center',
        }}
      >
        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', fontSize:'1.5rem'}}> {/* Centered header */}
          {header}
        </div>
        <div style={{ flexShrink: 0 }}> {/* Left-aligned icons */}
          <i className={classNames(iconClass, Style.red)} />
          <i className={classNames(iconClass, Style.amber)} />
          <i className={classNames(iconClass, Style.green)} />
        </div>
      </Box>
      <Box
        py={{ xs: '1rem', md: '2rem' }}
        px={{ xs: '2rem', md: '3rem' }}
        borderRadius={'0 0 0.5rem 0.5rem'}
        sx={{ backgroundColor: '#27242f' }}
        fontSize={'1.5rem'}
        fontFamily={'Courier New, Courier, monospace'}
      >
        {text}
      </Box>
    </Box>
  );
}

export default Terminal;
