import React from 'react';
import IconLink from './IconLink';
import { Box } from '@mui/material';

function PortfolioBlock(props) {
  const { image, live, source, title, subtitle } = props;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      p={1}
    >
      <Box
        width={'20vw'} // Adjust the width as needed to make the image smaller
        maxHeight={'50vh'} // Limit the image's height to 50% of the viewport height
        mb={1}
        style={{
          borderRadius: '25px',
          boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034),\n' +
            '  0 6.7px 5.3px rgba(0, 0, 0, 0.048),\n' +
            '  0 12.5px 10px rgba(0, 0, 0, 0.06),\n' +
            '  0 22.3px 17.9px rgba(0, 0, 0, 0.072),\n' +
            '  0 41.8px 33.4px rgba(0, 0, 0, 0.086),\n' +
            '  0 100px 80px rgba(0, 0, 0, 0.12)',
        }}
      >
        <img
          src={image}
          alt={'mockup'}
          style={{
            objectFit: 'scale-down',
            borderRadius: '25px',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      <Box
        textAlign="center"
        mt={1}
      >
        <h1 style={{ fontSize: '2rem', margin: 0, textAlign: 'center' }}>{title}</h1>
        {subtitle && <h2 style={{ fontSize: '1.25rem', margin: 0, textAlign: 'center' }}>{subtitle}</h2>}
      </Box>

      <Box
        className={'portfolio'}
        display={'flex'}
        flexDirection={'column'}
        gap={'0.5rem'}
        alignItems={'center'}
        fontSize={'1.5rem'}
        py={'0.5rem'}
      >
        {live && (
          <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
            <IconLink link={live} title={'Live Demo'} icon={'fa fa-safari'} />
          </Box>
        )}

        {source && (
          <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
            <IconLink link={source} title={'Source Code'} icon={'fa fa-code'} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PortfolioBlock;
