import React from 'react';
import Style from './About.module.scss';
import Terminal from './Terminal';
import { Box } from '@mui/material';
import { info } from '../../assets/info/Info';

export default function About() {
  function aboutMeText() {
    return (
        <p>
          {info.bio}
        </p>
    );
  }

  const skillsSection = (skill) => {
    const keyName = Object.keys(skill)[0];
    const values = skill[keyName];

    return (
      <div key={keyName}>
        <p style={{ color: info.baseColor }}> {keyName}</p>
        <ul className={Style.skills}>
          {values.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
      </div>
    );
  };

  const experience = (experience) => {
    const keyName = Object.keys(experience)[0];
    const values = experience[keyName];

    return (
      <div key={keyName}>
        <p style={{ color: info.baseColor }}> {keyName}</p>
        <ul className={Style.skills}>
          {values.map((experience) => (
            <li key={experience}>{experience}</li>
          ))}
        </ul>
      </div>
    );
  };

  function experienceText() {
    return (
      <>
        {
          info.experience.map((experience, idx) => (
            <div key={idx}>
              <p style={{ color: info.baseColor }}> {experience.company}</p>
              <p>
                {experience.description}
              </p>
            </div>
          ))
        }
      </>
    );
  }

  function skillsText() {
    return (
      <>
        {Object.keys(info.skills).map((key) => {
          return skillsSection({ [key]: info.skills[key] });
        })}
      </>
    );
  }

  function miscText() {
    return (
      <>
        <ul>
          {info.hobbies.map((hobby, index) => (
            <li key={index}>
              <Box component={'span'} mr={'1rem'}>
                {hobby.emoji}
              </Box>
              {hobby.label}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
      <Terminal text={aboutMeText()} header='About Me' />
      <Terminal text={experienceText()} header='Experience'/>
      <Terminal text={skillsText()} header='Skills' />
      <Terminal text={miscText()} header='Hobbies'/>
    </Box>
  );
}
